<template>
  <div class="layout-map">
    <svg id="layout_map" width="721" height="716" viewBox="0 0 721 716" fill="none" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <image id="image0_1_3" width="721" height="716" xlink:href="@/assets/images/master-layout.jpg" />
      <rect width="721" height="716" fill="url(#pattern0)" />
      <g v-for="(building, key) in masterBuildings" :key="key">
        <a v-if="building.image" :href="building.image" data-fancybox="">
          <path v-if="showhighlight" :id="building.key" @mouseover="highlightWing(building);"
            @mouseleave="removehighlight(building)" :opacity="building.opacity" :d="building.path" :fill="building.fill"
            fill-opacity="0.5" stroke="#5B5BBA" stroke-width="4" />
        </a>
        <path @click="popUpDetail(building)" v-if="showhighlight && !building.image" :id="building.key"
          @mouseover="highlightWing(building);" @mouseleave="removehighlight(building)" :opacity="building.opacity"
          :d="building.path" :fill="building.fill" fill-opacity="0.5" stroke="#5B5BBA" stroke-width="4" />
        <b-tooltip v-if="building.image" triggers="hover" :target="building.key"
          :custom-class="`${building.status === 'Sold' ? 'tooltip_sold' : ''} ${building.image ? 'tooltip_img' : ''}`">
          <HoverImage :building="building" />
        </b-tooltip>
        <b-tooltip v-if="!isBuildingSelected(building) && !building.image" triggers="hover click" :target="building.key"
          :custom-class="`${building.status === 'Sold' ? 'tooltip_sold' : ''} ${building.image ? 'tooltip_img' : ''}`">
          <HoverImage :building="building" />
        </b-tooltip>
        <b-tooltip v-if="isBuildingSelected(building) && !building.image" triggers="hover click" show
          :target="building.key"
          :custom-class="`${building.status === 'Sold' ? 'tooltip_sold' : ''} ${building.image ? 'tooltip_img' : ''}`">
          <HoverImage :building="building" />
        </b-tooltip>
      </g>
    </svg>
    <!-- 
			<div class="controls command">
				<div class="btn-group" role="group" aria-label="Basic example">
					<button id="zoom-in" @click="zoom(1)" class="btn btn-light btn-sm">+</button>
					<button id="reset" @click="reset" class="btn btn-light btn-sm">Reset</button>
					<button id="zoom-out"  @click="zoom(-1)" class="btn btn-light btn-sm">-</button>
				</div>
			</div> 
		-->
  </div>
</template>
<script>
import HoverImage from '../components/Inventory/HoverImage.vue';
import { masterJson } from '@/assets/scripts/utility.js';
import Panzoom from '@panzoom/panzoom';

export default {
  name: "MasterSvg",
  components: {
    HoverImage,
  },
  data() {
    return {
      isHovered: false,
      showhighlight: true,
      hoverData: {}
    }
  },
  watch: {
    selectedTypology(newValue) {
      if (newValue) {
        this.masterBuildings.forEach((element, index) => {
          if (element.key === newValue.key) {
            element.opacity = 0.8
          }
          else {
            element.opacity = 0.01
          }
        });
      }
    }
  },
  methods: {
    highlightWing(row) {
      this.masterBuildings.forEach((element, index) => {
        if (row.key === element.key) {
          row.opacity = 1
        }
      });
      this.showhighlight = false
      this.showhighlight = true
    },
    removehighlight(row) {
      this.masterBuildings.forEach((element, index) => {
        if (row.key === element.key && (!this.selectedTypology || element.key !== this.selectedTypology.key)) {
          row.opacity = 0.01
        }
        this.showhighlight = false
        this.showhighlight = true
      });
    },
    isBuildingSelected(building) {
      return this.selectedTypology && building.key === this.selectedTypology.key;
    },
    popUpDetail(building) {
      this.$store.dispatch('setTypology', building);
    },
    zoom(level) {
      level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn();

    },
    reset() {
      this.panzoom = Panzoom(document.getElementById('layout_map'), {
        scale: 1
      })
    }
  },
  mounted() {
    // let buildings = Object.assign({}, ...this.masterBuildings.filter(f => f.key === 'Plot 67'))
    // this.$store.dispatch("setTypology", buildings);
    this.$store.dispatch('setLoading', false);
  },
  computed: {
    masterBuildings() {
      return masterJson.Buildings;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    selectedWing() {
      return this.$store.getters.selectedWing;
    },
  }
}
</script>
<style scoped>
path {
  outline: none;
}

#image0_1_3 {
  pointer-events: none;
}

.layout-map a {
  outline: none;
  color: #000000;
}
</style>